import { allowedCurrencies } from "components/supplier-database/bookings-platform/constant";

export function formatCurrency(
    value: number,
    amountOfDecimals: number = 2,
    currency: string = "USD"
): string {
    const shouldShowCurrencyInStringFormat = (currency: string): boolean =>
        !allowedCurrencies.find(el => el === currency);

    return shouldShowCurrencyInStringFormat(currency)
        ? `${currency} ${new Intl.NumberFormat("en-US", {
              maximumFractionDigits: amountOfDecimals,
              minimumFractionDigits: amountOfDecimals
          }).format(value)}`
        : new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
              maximumFractionDigits: amountOfDecimals,
              minimumFractionDigits: amountOfDecimals
          }).format(value);
}

export function formatDayOrdinal(day: number): string {
    const suffixes = new Map([
        ["one", "st"],
        ["two", "nd"],
        ["few", "rd"],
        ["other", "th"]
    ]);
    const formatter = new Intl.PluralRules("en-US", { type: "ordinal" });
    const suffix = suffixes.get(formatter.select(day));
    return `${day}${suffix}`;
}
