import { countriesPrefix } from "constants/phonePrefix";
import { CodePrefix } from "constants/index";

export class CountryPhone {
    static selectedCountry = new Map();

    static findPhoneCountryPrefix = (countryName: string): void => {
        const prefix: string | undefined = countriesPrefix.find(
            (item: CodePrefix) => item.name === countryName
        )?.code;

        this.selectedCountry.set("prefix", prefix);
    };

    static getPhoneCountryPrefix = (): string => {
        const phoneNumberPrefix: string = this.selectedCountry.get("prefix");

        return phoneNumberPrefix;
    };

    static checkExistSuitablePrefix = (value: string | undefined): boolean => {
        const prefix: CodePrefix | undefined = countriesPrefix.find(
            (item: CodePrefix) => item.code === value
        );

        let isExist = (!prefix && value) || false;

        return !!isExist;
    };
}
